<script setup lang="ts">
  import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
  import { auth } from '@/lib/firebase-config';
  import { Button } from '@/components/ui/button';
  import { Card, CardHeader, CardContent } from '@/components/ui/card';

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('User signed in with Google:', result.user);
      })
      .catch((error) => {
        console.error('Error during Google sign-in:', error);
      });
  }
</script>

<template>
  <div class="flex items-center justify-center h-screen">
    <Card class="w-full max-w-md">
      <CardHeader class="text-center">
        <div class="flex justify-center mb-4">
          <img
            src="../../assets/ClybsyncBlack.png"
            alt="ClubSync Logo"
            class="h-12"
          />
        </div>
        <h2 class="text-2xl font-bold text-club-gray-800">
          Welcome to ClubSync
        </h2>
      </CardHeader>
      <CardContent>
        <p class="mb-6 text-center text-club-gray-600">
          Sign in to access your ClubSync account and manage your club
          efficiently.
        </p>
        <div class="space-y-4">
          <Button
            @click="signInWithGoogle"
            class="w-full text-white bg-club-blue-500 hover:bg-club-blue-600"
          >
            Sign in with Google
          </Button>
        </div>
      </CardContent>
    </Card>
  </div>
</template>

<style scoped></style>
