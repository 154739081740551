import { defineStore } from 'pinia';
import type { User } from 'firebase/auth';
import { DBUser } from '@/types/db-user';
import type { WSClient, ConnectedGroups } from '@/types/db-ws-client';
import { SubscriptionTier } from '@/types/subscriptions';
import { auth } from '@/lib/firebase-config';
import { type MessageSchedule } from '@/types/message-schedule';
import { type ScheduleFormData } from '@/types/schedule-form-data';

interface DailyOccupancyRate {
  date: string;
  rate: number;
}

interface OccupancyStats {
  last_seven_days_average: number;
  current_month_average: number;
  daily_rates: DailyOccupancyRate[];
}

interface TopResource {
  id: string;
  name: string;
  bookings: number;
}

interface PeriodStats {
  startDate: string;
  endDate: string;
  revenue: number;
  duration: number;
  totalBookings: number;
  canceledBookings: number;
  topResource: TopResource;
}

interface DailyStats {
  date: string;
  revenue: number;
  duration: number;
  topResource: TopResource;
}

interface OpenMatchesStats {
  current_month_total: PeriodStats;
  last_seven_days_total: PeriodStats;
  daily_stats: DailyStats[];
}

interface TenantInformation {
  tenant_id: string;
  tenant_uid: string;
  tenant_name: string;
  opening_hours: Record<string, string>;
}

const STORAGE_KEY = 'auth-store';

export const useAuthStore = defineStore({
  id: 'auth',

  state: () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    const defaults = {
      token: '',
      isLoggedIn: false,
      user: null as User | null,
      dbUser: { tier: SubscriptionTier.FREE } as DBUser,
      wsClientInfo: null as WSClient | null,
      connectedGroups: [] as ConnectedGroups[],
      isLoading: true,
      isTermsDialogOpen: false,
      tenantInformation: null as TenantInformation | null,
    };

    return stored ? { ...defaults, ...JSON.parse(stored) } : defaults;
  },

  getters: {
    isAuthenticated: (state) => state.isLoggedIn && !!state.user,
    currentUser: (state) => state.user,
    userSubscription: (state) => state.dbUser.tier,
    hasActiveSubscription: (state) => state.dbUser.tier > SubscriptionTier.FREE,
  },

  actions: {
    persistState() {
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          isLoggedIn: this.isLoggedIn,
          token: this.token,
          dbUser: this.dbUser,
          wsClientInfo: this.wsClientInfo,
          connectedGroups: this.connectedGroups,
        })
      );
    },
    clearWSClientInfo() {
      // Clear WS client info
      this.wsClientInfo = null;
      this.connectedGroups = [];

      // Clear wsClientId from dbUser
      if (this.dbUser) {
        this.dbUser = {
          ...this.dbUser,
          wsClientId: null,
        };
      }

      // Update localStorage with all changes
      this.persistState();
    },
    setToken(token: string) {
      this.token = token;
    },
    setUser(user: User | null) {
      this.user = user;
      this.isLoggedIn = !!user;
      this.persistState();
    },

    setDBUser(dbUser: DBUser) {
      this.dbUser = dbUser;
      this.persistState();
    },
    setWSClientInfo(wsClientInfo: WSClient | null) {
      this.wsClientInfo = wsClientInfo;
      if (wsClientInfo?.connectedGroups?.groups) {
        this.connectedGroups = wsClientInfo.connectedGroups.groups;
      }
    },
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    async logout() {
      localStorage.removeItem(STORAGE_KEY);
      this.token = '';
      this.user = null;
      this.isLoggedIn = false;
      this.dbUser = { tier: SubscriptionTier.FREE } as DBUser;
      this.wsClientInfo = null;
      this.connectedGroups = [];
    },
    async fetchTenantOpeningHours() {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}playtomic/tenant`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch tenant data');
        }

        const tenantData = await response.json();
        this.tenantInformation = tenantData[0];
        const openingHours = tenantData[0]?.opening_hours;

        if (!openingHours) {
          throw new Error('Opening hours data not found');
        }

        return openingHours;
      } catch (error) {
        console.error('Error fetching tenant opening hours:', error);
        throw error;
      }
    },

    async updateMessageSchedule(
      scheduleId: string,
      scheduleData: ScheduleFormData
    ): Promise<MessageSchedule> {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error('No auth token available');

      if (!this.wsClientInfo?.clientId) {
        throw new Error('Client ID not available');
      }

      const response = await fetch(
        `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }whatsapp/schedule/${scheduleId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(scheduleData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    },

    async fetchUserData() {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const dbUserResponse = await fetch(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}user/@me`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!dbUserResponse.ok) {
          if (dbUserResponse.status === 404) {
            // User doesn't exist, create a new user
            return this.createUser();
          }
          throw new Error(
            `Failed to fetch user data: ${dbUserResponse.statusText}`
          );
        }

        const dbUser: DBUser = await dbUserResponse.json();
        this.setDBUser(dbUser);

        if (dbUser.wsClientId) {
          const wsClientResponse = await fetch(
            `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/ws-clients/${
              dbUser.wsClientId
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (!wsClientResponse.ok) {
            throw new Error(
              `Failed to fetch WS client info: ${wsClientResponse.statusText}`
            );
          }

          const wsClientInfo: WSClient = await wsClientResponse.json();
          this.setWSClientInfo(wsClientInfo);
          this.persistState();
        }

        return dbUser;
      } catch (error) {
        console.error('Error in fetchUserData:', error);
        throw error;
      }
    },
    async acceptAgreement() {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}user/agree-to-terms`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to save terms agreement');
        }
        this.agreeToTerms();
      } catch (error) {
        console.error('Error saving terms agreement:', error);
      }
    },
    async createUser(): Promise<DBUser> {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}user/@me`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to create user: ${response.statusText}`);
        }

        const newUser: DBUser = await response.json();
        this.setDBUser(newUser);

        return this.fetchUserData();
      } catch (error) {
        console.error('Error in createUser:', error);
        throw error;
      }
    },
    async updateConnectedGroups(groups: ConnectedGroups[]) {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const response = await fetch(
          `${
            import.meta.env.VITE_BACKEND_ENDPOINT
          }whatsapp/ws-clients/groups/set/${this.wsClientInfo?.clientId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ groups }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to update connected groups');
        }

        this.connectedGroups = groups;
        if (this.wsClientInfo) {
          this.wsClientInfo.connectedGroups = { groups };
        }
      } catch (error) {
        console.error('Error updating connected groups:', error);
      }
    },
    async fetchConnectedGroups() {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/ws-clients/${
            this.wsClientInfo?.clientId
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch connected groups');
        }

        const data = await response.json();
        this.connectedGroups = data.connectedGroups.groups;
        if (this.wsClientInfo) {
          this.wsClientInfo.connectedGroups = data.connectedGroups;
        }
      } catch (error) {
        console.error('Error fetching connected groups:', error);
      }
    },
    async fetchTournaments() {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}playtomic/tournaments`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch tournaments');
      return response.json();
    },

    async fetchTenantCategories() {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}playtomic/tenant/categories`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch tenant categories');
      return response.json();
    },

    async fetchCategoryMembers(categoryId: string) {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }playtomic/tenant/category-members/${categoryId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch category members');
      return response.json();
    },

    async fetchPlayerStats(memberId: string) {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }playtomic/tenant/user/stats/${memberId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch player stats');
      return response.json();
    },
    async fetchSentMessages() {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/messages/sent/${
          this.wsClientInfo?.clientId
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch messages');
      return response.json();
    },
    async fetchOpenMatches() {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }playtomic/matches/open-matches`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch open matches');
      return response.json();
    },
    async fetchOccupancyData() {
      try {
        const token = await auth.currentUser?.getIdToken();
        const response = await fetch(
          `${
            import.meta.env.VITE_BACKEND_ENDPOINT
          }playtomic/tenant/resources-stats`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch occupancy data');
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error in fetchOccupancyData:', error);
        throw error;
      }
    },
    async fetchCourtAvailability() {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(
        `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }playtomic/tenant/court-availability`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch court availability');
      return response.json();
    },

    async fetchTenantOccupancyStats(
      startingDate?: string,
      endingDate?: string
    ) {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        let url = `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }playtomic/tenant/occupancy-stats`;

        const params = new URLSearchParams();
        if (startingDate) params.append('startingDate', startingDate);
        if (endingDate) params.append('endingDate', endingDate);

        const queryString = params.toString();
        if (queryString) {
          url = `${url}?${queryString}`;
        }

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Tenant not found');
          }
          if (response.status === 400) {
            throw new Error('Invalid date range');
          }
          throw new Error('Failed to fetch occupancy statistics');
        }

        const data: OccupancyStats = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching tenant occupancy statistics:', error);
        throw error;
      }
    },

    async fetchTenantOpenMatchesStats(
      startingDate?: string,
      endingDate?: string
    ) {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        let url = `${
          import.meta.env.VITE_BACKEND_ENDPOINT
        }playtomic/tenant/open-matches-stats`;

        const params = new URLSearchParams();
        if (startingDate) params.append('startingDate', startingDate);
        if (endingDate) params.append('endingDate', endingDate);

        const queryString = params.toString();
        if (queryString) {
          url = `${url}?${queryString}`;
        }

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Tenant not found');
          }
          if (response.status === 400) {
            throw new Error('Invalid date range');
          }
          throw new Error('Failed to fetch open matches statistics');
        }

        const data: OpenMatchesStats = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching tenant open matches statistics:', error);
        throw error;
      }
    },
    async fetchWhatsAppGroups() {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error('No auth token available');

      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/groups-raw`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    },
    async fetchMessageSchedules(): Promise<MessageSchedule[]> {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error('No auth token available');

      if (!this.wsClientInfo?.clientId) {
        await this.fetchUserData();

        if (!this.wsClientInfo?.clientId) {
          throw new Error('Client ID not available');
        }
      }

      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/messages/schedule/${
          this.wsClientInfo.clientId
        }`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    },
    async createMessageSchedule(
      scheduleData: ScheduleFormData
    ): Promise<MessageSchedule> {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error('No auth token available');

      if (!this.wsClientInfo?.clientId) {
        throw new Error('Client ID not available');
      }

      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/messages/schedule/${
          this.wsClientInfo.clientId
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(scheduleData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    },
    async deleteMessageSchedule(scheduleId: string): Promise<void> {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error('No auth token available');

      if (!this.wsClientInfo?.clientId) {
        throw new Error('Client ID not available');
      }

      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}whatsapp/messages/schedule/${
          this.wsClientInfo.clientId
        }/${scheduleId}`,
        {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    },
    async setTenantId(tenantId: string) {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) throw new Error('No auth token available');

        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_ENDPOINT}user/set-tenant`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ tenantId }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to set tenant ID');
        }
        this.dbUser.tenantId = tenantId;
        await this.fetchUserData();
      } catch (error) {
        console.error('Error in setTenantId:', error);
        throw error;
      }
    },
    openTermsDialog() {
      this.isTermsDialogOpen = true;
    },
    closeTermsDialog() {
      this.isTermsDialogOpen = false;
    },
    agreeToTerms() {
      this.setDBUser({ ...this.dbUser, agreedToTerms: true });
      this.closeTermsDialog();
    },
    async handleWaitTermsDialogSigned() {
      if (!this.dbUser.agreedToTerms) {
        this.openTermsDialog();
        return new Promise((resolve) => {
          const timeout = setTimeout(() => {
            clearInterval(interval);
            resolve(null);
          }, 60000);
          const interval = setInterval(() => {
            if (this.dbUser.agreedToTerms) {
              clearInterval(interval);
              clearTimeout(timeout);
              resolve(null);
            }
          }, 1000);
        });
      }
    },
  },
});
