<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { onAuthStateChanged } from 'firebase/auth';
  import { auth } from '@/lib/firebase-config';
  import { useAuthStore } from '@/stores/auth-store';
  import Loader from './components/ui/loaders/Loader.vue';

  const router = useRouter();
  const authStore = useAuthStore();
  const isInitializing = ref(true);

  async function handleInitialSetup() {
    try {
      await authStore.fetchUserData();

      if (router.currentRoute.value.path === '/') {
        router.push('/dashboard');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error during initial setup:', error.message);
      } else {
        console.error('An unknown error occurred during initial setup');
      }
    } finally {
      isInitializing.value = false;
    }
  }

  onMounted(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        authStore.setUser(user);
        handleInitialSetup();
      } else {
        isInitializing.value = false;
        if (router.currentRoute.value.path !== '/') {
          router.push('/');
        }
      }
    });
  });
</script>

<template>
  <div
    class="flex items-center justify-center w-screen h-screen"
    v-if="isInitializing"
  >
    <Loader />
  </div>
  <router-view v-else />
</template>
