import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AuthView from '@/views/auth/auth.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Auth',
    component: AuthView,
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/dashboardSubViews/dashboard.vue'),
      },
      {
        path: 'connect',
        name: 'Connect',
        component: () => import('@/views/dashboardSubViews/connect.vue'),
      },
      {
        path: 'wa-groups',
        name: 'WA Groups',
        component: () => import('@/views/dashboardSubViews/WaGroups.vue'),
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import('@/views/dashboardSubViews/messages.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/dashboardSubViews/settings.vue'),
      },
      {
        path: 'playerinfo',
        name: 'playerinfo',
        component: () => import('../views/dashboardSubViews/playerInfo.vue'),
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: () => import('@/views/dashboard/pricing.vue'),
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('@/views/dashboardSubViews/faq.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
