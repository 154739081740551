<script lang="ts" setup></script>

<template>
  <div class="loader-container">
    <div class="loader-wrapper">
      <span class="loader"></span>
    </div>
  </div>
</template>

<style scoped>
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    width: full;
  }

  .loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    color: #405be7;
    position: relative;
    margin-bottom: 15px; /* Add space between loader and text */
  }

  .loader:before,
  .loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }

  .loader:after {
    color: #ff3d00;
    transform: rotateY(70deg);
    animation-delay: 0.4s;
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: 0.2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 0.2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -0.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -0.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
  }
</style>
