import { createApp } from 'vue';
import './assets/index.css';
import App from './App.vue';
import router from '@/router/index';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

const app = createApp(App);
const pinia = createPinia();

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing configuration
    tracesSampleRate: 0.2,
    tracePropagationTargets: [
      'localhost',
      'https://app.clubsync.co.za',
      /^https:\/\/clubsync-saas--pr\d+-[a-z0-9-]+\.web\.app$/,
    ],
    // Production-appropriate session replay rates
    replaysSessionSampleRate: 0.01, // Capture 1% of all sessions
    replaysOnErrorSampleRate: 0.5, // Capture 50% of sessions with errors
  });
}

app.use(pinia);
app.use(router);
app.mount('#app');
