// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyDJ1YJuujnSrqGuEEZkIKyWEkWnjWkbUDw',
  authDomain: 'clubsync-d53e5.firebaseapp.com',
  projectId: 'clubsync-d53e5',
  storageBucket: 'clubsync-d53e5.appspot.com',
  messagingSenderId: '251798647167',
  appId: '1:251798647167:web:c76d07982e0a2382472a4a',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, auth };
